var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('slide-x-right-transition', [_vm.show ? _c('div', {
    staticClass: "table-settings"
  }, [_c('div', {
    staticClass: "table-settings__bg",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "table-settings__block"
  }, [_c('div', {
    staticClass: "table-settings__header"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('svg', {
    staticClass: "table-settings__header-btn-close",
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  }, [_c('path', {
    attrs: {
      "d": "M17.5 0L10 7.5L2.5 0L0 2.5L7.5 10L0 17.5L2.5 20L10 12.5L17.5 20L20 17.5L12.5 10L20 2.5L17.5 0Z",
      "fill": "black",
      "fill-opacity": "0.65"
    }
  })])]), _c('div', {
    staticClass: "table-settings__content"
  }, [_c('div', {
    staticClass: "table-settings__title"
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]), _c('scrollable', {
    staticClass: "table-settings__columns-scroll-block"
  }, [_c('draggable', _vm._b({
    staticClass: "table-settings__columns-drag-block",
    on: {
      "start": function ($event) {
        _vm.drag = true;
      },
      "end": function ($event) {
        _vm.drag = false;
      }
    },
    model: {
      value: _vm.tableData,
      callback: function ($$v) {
        _vm.tableData = $$v;
      },
      expression: "tableData"
    }
  }, 'draggable', _vm.dragOptions, false), [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": !_vm.drag ? 'flip-list' : null
    }
  }, _vm._l(_vm.tableData, function (el) {
    return _c('div', {
      key: el.name,
      staticClass: "table-settings__columns-drag-item",
      attrs: {
        "disabled": el.disabled
      }
    }, [_c('checkbox', {
      attrs: {
        "title": el.title
      },
      model: {
        value: el.show,
        callback: function ($$v) {
          _vm.$set(el, "show", $$v);
        },
        expression: "el.show"
      }
    }), _c('svg', {
      attrs: {
        "width": "14",
        "height": "9",
        "viewBox": "0 0 14 9",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M2 2H14",
        "stroke": "#A4A4AD",
        "stroke-width": "3",
        "stroke-linecap": "square"
      }
    }), _c('path', {
      attrs: {
        "d": "M2 7H14",
        "stroke": "#A4A4AD",
        "stroke-width": "3",
        "stroke-linecap": "square"
      }
    })])], 1);
  }), 0)], 1)], 1)], 1), _c('div', {
    staticClass: "table-settings__footer"
  }, [_c('button', {
    staticClass: "btn-outline",
    on: {
      "click": _vm.reset
    }
  }, [_vm._v("Сбросить")]), _c('button', {
    staticClass: "btn-blue",
    on: {
      "click": _vm.apply
    }
  }, [_vm._v("Применить")])])])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import AppSelect from "@/components/AppSelect.vue";
import { getEntitiesListArray, getMarketplacesListArray } from "@/utils/enums";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    const select_type_items = [...getEntitiesListArray()];
    const select_marketplace_items = [...getMarketplacesListArray()];
    const initial_new_list = {
      title: "",
      entity: select_type_items[0],
      mp: select_marketplace_items[0],
      description: ""
    };
    return {
      initial_new_list,
      new_list: {
        ...initial_new_list
      },
      entity: null,
      mp: null,
      select_type_items,
      select_marketplace_items,
      loading: false
    };
  },
  mounted() {},
  methods: {
    onOpen({
      entity = null,
      mp = null
    } = {}) {
      this.new_list = {
        ...this.initial_new_list
      };
      this.entity = entity;
      if (entity) {
        this.new_list.entity = this.select_type_items.find(i => i.id === entity);
      }
      this.mp = mp;
      if (mp) {
        this.new_list.mp = this.select_marketplace_items.find(i => i.id === mp);
      }
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        var _this$new_list$entity, _this$new_list$mp;
        const result = await this.$store.dispatch("list/addList", {
          title: this.new_list.title,
          entity: (_this$new_list$entity = this.new_list.entity) === null || _this$new_list$entity === void 0 ? void 0 : _this$new_list$entity.id,
          mp: (_this$new_list$mp = this.new_list.mp) === null || _this$new_list$mp === void 0 ? void 0 : _this$new_list$mp.id,
          description: this.new_list.description
        });
        this.loading = false;
        close();
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    valid() {
      var _this$new_list$title, _this$new_list$entity2, _this$new_list$mp2;
      return ((_this$new_list$title = this.new_list.title) === null || _this$new_list$title === void 0 ? void 0 : _this$new_list$title.length) && ((_this$new_list$entity2 = this.new_list.entity) === null || _this$new_list$entity2 === void 0 ? void 0 : _this$new_list$entity2.id) && ((_this$new_list$mp2 = this.new_list.mp) === null || _this$new_list$mp2 === void 0 ? void 0 : _this$new_list$mp2.id);
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Далее",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal,
    AppSelect
  }
};
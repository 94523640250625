import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import AppSelect from "@/components/AppSelect";
export default {
  components: {
    AppSelect
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    }
  },
  data() {
    const pageSizes = [20, 50, 100];
    if (!pageSizes.includes(this.value.pageSize)) {
      pageSizes.unshift(this.value.pageSize);
    }
    const pageSizesList = pageSizes.map(i => {
      return {
        id: i,
        title: i
      };
    });
    return {
      pageSelector: this.value,
      selectOptions: pageSizesList
    };
  },
  computed: {
    selectModel: {
      get() {
        return this.selectOptions.find(i => i.id === this.value.pageSize);
      },
      set(v) {
        this.$emit("input", {
          ...this.value,
          pageSize: v.id,
          page: 1
        });
      }
    },
    page: {
      get() {
        return this.value.page;
      },
      set(v) {
        this.$emit("input", {
          ...this.value,
          page: v
        });
      }
    },
    range() {
      if (!this.count) {
        return null;
      }
      const offset = this.value.pageSize * (this.page - 1);
      return [offset + 1, offset + this.count];
    },
    pagesCount() {
      return Math.ceil(this.total / this.value.pageSize);
    },
    links() {
      const pagesList = [...Array(this.pagesCount).keys()].map(i => i + 1);
      const visible = pagesList.filter(page => {
        // show first, last, current (and neighbors) pages
        return [1, this.page - 1, this.page, this.page + 1, this.pagesCount].includes(page);
      });
      const nextSiblingNotSequence = visible.filter((item, i) => visible[i + 1] && item + 1 !== visible[i + 1]);
      nextSiblingNotSequence.forEach(item => {
        visible.splice(visible.indexOf(item) + 1, 0, "...");
      });
      return visible;
    }
  }
};
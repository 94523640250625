import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { SlideYUpTransition } from "vue2-transitions";
import Checkbox from "@/components/Checkbox.vue";
export default {
  model: {
    prop: "modelValue",
    event: "select"
  },
  props: {
    modelValue: {
      default: null
    }
  },
  data() {
    return {
      isOpen: false,
      items: [],
      search: ""
    };
  },
  methods: {
    closeSelect() {
      this.isOpen = false;
    },
    openSelect() {
      this.isOpen = true;
      this.items = JSON.parse(JSON.stringify(this.modelValue));
      this.search = "";
    },
    toggleSelect() {
      this.isOpen = !this.isOpen;
      this.items = JSON.parse(JSON.stringify(this.modelValue));
      this.search = "";
    },
    apply() {
      this.$emit("select", this.items);
      this.closeSelect();
    }
  },
  computed: {
    title() {
      let selected = [...this.modelValue].filter(item => item.selected);
      if (selected.length == 0) return "Не выбрано";else if (selected.length == [...this.modelValue].length) {
        if (this.modelValue[0].type === "category") return "Все категории";else if (this.modelValue[0].type === "brand") return "Все бренды";else return "Выбраны все";
      } else {
        let res = "";
        selected.forEach(el => {
          if (res.length > 0) res += ", ";
          res += el.title;
        });
        return res;
      }
    },
    filtersItems() {
      if (this.search.length > 0) return this.items.filter(item => item.title.toLowerCase().indexOf(this.search.toLowerCase()) >= 0);else return this.items;
    }
  },
  // mounted() {
  //     this.items = [...this.modelValue]
  // },
  components: {
    SlideYUpTransition,
    Checkbox
  }
};
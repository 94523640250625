import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "datatable-table",
    class: {
      'show-gradient': _vm.showGradient,
      selectable: _vm.selectable
    }
  }, [_c('div', {
    staticClass: "table-block-wrapper"
  }, [_vm.loading || _vm.forceLoading || _vm.error || !_vm.items.length && !_vm.addable ? _c('div', {
    staticClass: "loading-overlay"
  }, [_c('div', {
    staticClass: "loading"
  }, [_vm.loading || _vm.forceLoading ? _c('loading-indicator') : _vm.error ? _c('div', {
    staticClass: "loading-text loading-error"
  }, [_vm._v(" Возникла ошибка. Попробуйте перезагрузить страницу ")]) : !_vm.items.length ? _c('div', {
    staticClass: "loading-text loading-empty"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.noData)
    }
  })]) : _vm._e()], 1)]) : _vm._e(), _c('vue-custom-scrollbar', {
    staticClass: "table-block",
    class: {
      actions: _vm.columns[_vm.columns.length - 1] && _vm.columns[_vm.columns.length - 1].type === 'actions'
    },
    attrs: {
      "settings": _vm.scrollSettings
    },
    on: {
      "ps-scroll-x": _vm.handleScroll
    }
  }, [_c('table', {
    staticClass: "datatable-table__table"
  }, [_c('thead', {
    staticClass: "table-head"
  }, [_c('tr', {
    staticClass: "datatable-table__thead"
  }, [_vm.selectable ? _c('td', {
    staticClass: "datatable-table__thead-th th-checkbox",
    style: _vm.getTdStyle(),
    attrs: {
      "width": "50"
    }
  }, [_c('div', {
    staticClass: "datatable-table__thead-th-cell"
  }, [_c('checkbox', {
    attrs: {
      "rounded": ""
    },
    model: {
      value: _vm.selectAll,
      callback: function ($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  })], 1)]) : _vm._e(), _vm._l(_vm.tableHead, function (head, index) {
    return _c('td', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: head.show,
        expression: "head.show"
      }],
      key: index,
      staticClass: "datatable-table__thead-th",
      style: head.style,
      attrs: {
        "width": head.width
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.setSortBy(head);
        }
      }
    }, [head.lastFixed ? _c('div', {
      staticClass: "gradient"
    }) : _vm._e(), head.sort ? _c('div', {
      staticClass: "datatable-table__thead-th-cell",
      class: {
        'datatable-table__thead-th-cell-sorted': head.name == _vm.sortedBy && head.sort,
        'align-right': ['number', 'money', 'percent'].includes(head.type) || head.filter === 'numberRange'
      }
    }, [_vm._v(" " + _vm._s(head.title) + " "), head.name == _vm.sortedBy && head.sort ? _c('img', {
      staticClass: "datatable-table__sorted-arrow",
      class: {
        'sorted-desc': !_vm.sortedAsc
      },
      attrs: {
        "src": require(`@/assets/images/icons/table_sort_arrow.svg`)
      }
    }) : _vm._e()]) : _c('div', {
      staticClass: "datatable-table__thead-th-cell cursor-default",
      class: {
        'datatable-table__thead-th-cell-sorted': head.name == _vm.sortedBy && head.sort,
        'align-right': ['number', 'money', 'percent'].includes(head.type) || head.filter === 'numberRange'
      }
    }, [_vm._v(" " + _vm._s(head.title) + " "), head.name == _vm.sortedBy && head.sort ? _c('img', {
      staticClass: "datatable-table__sorted-arrow",
      class: {
        'sorted-desc': !_vm.sortedAsc
      },
      attrs: {
        "src": require(`@/assets/images/icons/table_sort_arrow.svg`)
      }
    }) : _vm._e()])]);
  })], 2)]), _c('tbody', {
    staticClass: "table-body"
  }, [_vm._l(_vm.actualData, function (item, index) {
    return _c('tr', {
      key: index,
      staticClass: "datatable-table__tr",
      class: {
        clickable: _vm.rowClickable,
        disabled: item.disabled
      },
      on: {
        "click": e => _vm.$emit('rowClick', item)
      }
    }, [_vm.selectable ? _c('td', {
      staticClass: "datatable-table__td-w50",
      style: _vm.getTdStyle()
    }, [_c('checkbox', {
      attrs: {
        "model-value": _vm.isChecked(item),
        "rounded": ""
      },
      on: {
        "change": function ($event) {
          return _vm.onCheckbox($event, item);
        }
      }
    })], 1) : _vm._e(), _vm._l(_vm.tableHead, function (h, index) {
      return _c('td', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: h.show,
          expression: "h.show"
        }],
        key: index,
        staticClass: "datatable-table__td",
        style: h.style
      }, [h.lastFixed ? _c('div', {
        staticClass: "gradient"
      }) : _vm._e(), _c('div', {
        staticClass: "datatable-table__td-cell",
        class: {
          'align-right': ['number', 'money', 'percent'].includes(h.type) || h.filter === 'numberRange',
          'no-overflow': h.noOverflow
        }
      }, [h.getImage && h.getImage(item) ? _c('div', {
        staticClass: "datatable-table__td-title-image"
      }, [_c('img', {
        class: {
          hoverUp: index > 3
        },
        attrs: {
          "src": _vm.wb7p_image(h.getImage(item))
        },
        on: {
          "error": function ($event) {
            $event.target.src = require('@/assets/images/img.svg');
          }
        }
      })]) : _vm._e(), _c('div', {
        class: ['datatable-table__td-title-text']
      }, [h.type === 'money' ? [item[h.name] === -1 ? _c('span', {
        staticClass: "gray"
      }, [_vm._v(" — ")]) : item['_css_' + h.name] != '' ? _c('span', {
        class: item['_css_' + h.name]
      }, [_vm._v(" " + _vm._s(item[h.name]) + " ₽ ")]) : _c('span', [_vm._v(" " + _vm._s(item[h.name]) + " ₽ ")])] : h.type === 'percent' ? [item[h.name] === -1 ? _c('span', {
        staticClass: "gray"
      }, [_vm._v(" — ")]) : item[h.name] === null ? _c('span') : _c('span', [_vm._v(" " + _vm._s(item[h.name]) + " % ")])] : h.type === 'slot' ? [_vm._t(h.name, null, null, {
        ..._vm.rawResponse,
        item
      })] : h.type === 'actions' ? [_c('app-select', {
        attrs: {
          "items": _vm.getActionsItems(h),
          "icon-misc": "",
          "width": "28"
        },
        on: {
          "select": function ($event) {
            return _vm.onActionSelect(item, $event);
          }
        }
      })] : h.type === 'link' ? [h.getLink(item).text === '&:NA' ? [_c('span', {
        staticClass: "gray"
      }, [_vm._v(" — ")])] : ['Ozon', 'ООО ВАЙЛДБЕРРИЗ', 'ВАЙЛДБЕРРИЗ ООО'].includes(h.getLink(item).text) ? [_c('span', {
        staticClass: "gray link-text",
        domProps: {
          "innerHTML": _vm._s(h.getLink(item).text)
        }
      }), h.getLink(item).slotRight ? _c('span', {
        staticClass: "link-slot-right",
        class: {
          hover: h.getLink(item).slotRightHover
        },
        domProps: {
          "innerHTML": _vm._s(h.getLink(item).slotRight)
        }
      }) : _vm._e()] : [h.getLink(item).route ? _c('router-link', {
        attrs: {
          "to": h.getLink(item).route,
          "title": h.getLink(item).text,
          "target": h.getLink(item).target
        }
      }, [_c('span', {
        staticClass: "link-text",
        domProps: {
          "innerHTML": _vm._s(h.getLink(item).text)
        }
      }), h.getLink(item).slotRight ? _c('span', {
        staticClass: "link-slot-right",
        class: {
          hover: h.getLink(item).slotRightHover
        },
        domProps: {
          "innerHTML": _vm._s(h.getLink(item).slotRight)
        }
      }) : _vm._e()]) : _vm._e(), h.getLink(item).href ? _c('a', {
        attrs: {
          "href": h.getLink(item).href,
          "title": h.getLink(item).text,
          "target": "_blank"
        }
      }, [_c('span', {
        staticClass: "link-text",
        domProps: {
          "innerHTML": _vm._s(h.getLink(item).text)
        }
      }), h.getLink(item).slotRight ? _c('span', {
        staticClass: "link-slot-right",
        class: {
          hover: h.getLink(item).slotRightHover
        },
        domProps: {
          "innerHTML": _vm._s(h.getLink(item).slotRight)
        }
      }) : _vm._e()]) : _vm._e()]] : h.type === 'date' ? [item[h.name] && _vm.formatDate(item[h.name]).slice(0, 10) == '1970-01-01' ? _c('span', {
        staticClass: "gray"
      }, [_vm._v(" — ")]) : item['_css_' + h.name] != '' ? _c('span', {
        class: item['_css_' + h.name]
      }, [_vm._v(" " + _vm._s(_vm.formatDate(item[h.name])) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.formatDate(item[h.name])) + " ")])] : h.type === 'datetime' ? [_vm._v(" " + _vm._s(_vm.formatDateTime(item[h.name])) + " ")] : h.type === 'datetimeLocal' ? [_vm._v(" " + _vm._s(_vm.formatDateTimeLocal(item[h.name])) + " ")] : [h.getText ? _c('span', {
        domProps: {
          "innerHTML": _vm._s(h.getText(item[h.name], item))
        }
      }) : h.values && h.values[item[h.name]] !== undefined ? _c('span', [_vm._v(" " + _vm._s(h.values[item[h.name]]) + " ")]) : item[h.name] === -1 ? _c('span', {
        staticClass: "gray"
      }, [_vm._v(" — ")]) : item['_css_' + h.name] !== '' ? _c('span', {
        class: item['_css_' + h.name]
      }, [_vm._v(" " + _vm._s(item[h.name]) + " ")]) : _c('span', {
        attrs: {
          "title": item[h.name]
        }
      }, [_vm._v(" " + _vm._s(item[h.name]) + " ")])]], 2)])]);
    })], 2);
  }), _vm.addable ? _c('tr', {
    staticClass: "datatable-table__tr",
    on: {
      "click": function ($event) {
        return _vm.$emit('addNew');
      }
    }
  }, [_c('td', {
    attrs: {
      "colspan": _vm.tableHead.length + Number(_vm.selectable)
    }
  }, [_c('div', {
    staticClass: "datatable-table__add-new"
  }, [_c('span', [_vm._v("Добавить")])])])]) : _vm._e()], 2)])])], 1), _vm.$slots.footer ? _c('div', {
    staticClass: "datatable-table__bottom"
  }, [_vm._t("footer")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
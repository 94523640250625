var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "select",
    class: {
      'select-mini': _vm.mini
    }
  }, [_c('div', {
    staticClass: "select__main",
    class: {
      'select-open': _vm.isOpen
    },
    style: {
      width: `${_vm.width}px`
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.toggleSelect.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "select-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('svg', {
    staticClass: "select-arrow",
    class: {
      'select-arrow-mini': _vm.mini
    },
    attrs: {
      "width": "12",
      "height": "8",
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.293 0.439453L5.99997 4.73245L1.70697 0.439453L0.292969 1.85345L5.99997 7.56045L11.707 1.85345L10.293 0.439453Z",
      "fill": "black"
    }
  })])]), _c('slide-y-up-transition', [_vm.isOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeSelect,
      expression: "closeSelect"
    }],
    staticClass: "select__popup",
    class: {
      'select__popup-up': _vm.openUp
    }
  }, [_vm.search ? _c('div', {
    staticClass: "search-wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchValue,
      expression: "searchValue"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    domProps: {
      "value": _vm.searchValue
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchValue = $event.target.value;
      }
    }
  }), _c('svg', {
    staticClass: "search-icon",
    attrs: {
      "viewBox": "0 0 512 512"
    }
  }, [_c('path', {
    attrs: {
      "fill": "grey",
      "d": "M344.5,298c15-23.6,23.8-51.6,23.8-81.7c0-84.1-68.1-152.3-152.1-152.3C132.1,64,64,132.2,64,216.3  c0,84.1,68.1,152.3,152.1,152.3c30.5,0,58.9-9,82.7-24.4l6.9-4.8L414.3,448l33.7-34.3L339.5,305.1L344.5,298z M301.4,131.2  c22.7,22.7,35.2,52.9,35.2,85c0,32.1-12.5,62.3-35.2,85c-22.7,22.7-52.9,35.2-85,35.2c-32.1,0-62.3-12.5-85-35.2  c-22.7-22.7-35.2-52.9-35.2-85c0-32.1,12.5-62.3,35.2-85c22.7-22.7,52.9-35.2,85-35.2C248.5,96,278.7,108.5,301.4,131.2z"
    }
  })])]) : _vm._e(), _vm._t("popup-top"), _vm.items.length > 3 && _vm.items.length === _vm.itemsFiltered.length ? _c('div', {
    staticClass: "select__item-all",
    class: {
      'select__item-active': _vm.selectAll
    },
    on: {
      "click": _vm.toggleAll
    }
  }, [_c('checkbox', {
    attrs: {
      "model-value": _vm.selectAll,
      "rounded": "",
      "wrapper": "div"
    }
  }), _c('div', {
    staticClass: "select__item-title"
  }, [_vm._v("Выбрать все")])], 1) : _vm._e(), _vm._l(_vm.itemsFiltered, function (item) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.hide,
        expression: "!item.hide"
      }],
      key: item.id,
      staticClass: "select__item",
      class: {
        'select__item-active': _vm.isChecked(item),
        'select__item-disabled': item.disabled
      },
      on: {
        "click": function ($event) {
          _vm.onCheckbox(!_vm.isChecked(item), item);
        }
      }
    }, [!item.disabled ? _c('checkbox', {
      attrs: {
        "model-value": _vm.isChecked(item),
        "rounded": "",
        "wrapper": "div",
        "color": item.color || undefined
      }
    }) : _vm._e(), _c('div', {
      staticClass: "select__item-title"
    }, [_vm._v(_vm._s(item.title))])], 1);
  })], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import DataTableTable from "./Table.vue";
import TableSettingsFilters from "@/components/DataTable/TableSettingsFilters.vue";
import TableSettingsColumns from "@/components/DataTable/TableSettingsColumns.vue";
import Pagination from "./Pagination.vue";
import SelectedTableItems from "@/components/DataTable/SelectedTableItems.vue";
import ModalListAddItems from "@/components/modals/ModalListAddItems.vue";
import ModalListMAddItems from "@/components/modals/ModalListMAddItems.vue";
import hash from "object-hash";
import { today } from "@/utils/dates";
import ExportJsonExcel from "js-export-excel";

/**
 * Dynamic table component for displaying data list from API with settings, filters, sorting and pagination
 */
//export default {
export const DataTable = {
  components: {
    DataTableTable,
    TableSettingsFilters,
    TableSettingsColumns,
    Pagination,
    SelectedTableItems,
    ModalListAddItems,
    ModalListMAddItems
  },
  props: {
    /**
     * Table identifier - used for saving settings and filters
     */
    tableId: {
      type: String,
      required: true
    },
    /**
     * Table unique key
     * Used for connection between DataTable and DataTableActions components
     */
    id: {
      type: [String, Number],
      default: undefined
    },
    /**
     * Object property name that identifies the item
     */
    itemId: {
      type: String,
      default: "id"
    },
    /**
     * Columns list in following format:
     * {
     *  title: String - Human-readable text for column heading, filters and settings
     *  name: String - Column identifier
     *  position: Number - Default column sorting position. Will be overrided by user's settings
     *  show: Boolean - Default column visibility state. Will be overrided by user's settings
     *  width: Number | String - Column width in pixels
     *  filter: 'text' | 'textStrict' | 'numberRange' | 'dateRange' | 'select' | undefined - Filter type for this column
     * }
     */
    columns: {
      type: Array,
      required: true
    },
    /**
     * Async function that should return rows list array
     */
    loader: {
      type: Function,
      required: true
    },
    /**
     * Pass any value. When this value changes, loader will be called automatically due to Vue watcher
     * You can access this property in loader function through ({ config }) argument property
     */
    loaderConfig: {
      type: Object,
      default: null
    },
    /**
     * Default amount of rows per page
     */
    pageSize: {
      type: Number,
      default: 20
    },
    /**
     * Use filters, settings, etc
     */
    configurable: {
      type: Boolean,
      default: true
    },
    /**
     * Send API requests to load/save setttings (columns order and visiblity)
     */
    persistSettings: {
      type: Boolean,
      default: true
    },
    /**
     * Use item's selection
     */
    selectable: {
      type: Boolean,
      default: true
    },
    addable: {
      type: Boolean,
      default: false
    },
    selectAction: {
      type: String,
      default: "add",
      validate: v => ["add", "remove", "update", "multiple-approve", "repricer-products-edit", "repricer-products-edit-compensation"].includes(v)
    },
    /**
     * Show pagination
     */
    paginatable: {
      type: Boolean,
      default: true
    },
    stickyColumns: {
      type: Number,
      default: 1
    },
    track: {
      type: Object,
      default: null
    },
    fileName: {
      type: String,
      default: null
    },
    rowClickable: {
      type: Boolean,
      default: false
    },
    noData: {
      type: String,
      default: "Нет данных"
    },
    forceLoading: {
      type: Boolean,
      default: false
    },
    qSkip_ViewButton: {
      type: Boolean,
      default: false
    },
    qSkip_FilterButton: {
      type: Boolean,
      default: false
    },
    qSkip_ExportButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let filters = null;
    if (this.$route.query.filters) {
      try {
        const parsed = JSON.parse(this.$route.query.filters);
        if (typeof parsed === "object") {
          filters = parsed;
        }
        const query = {
          ...this.$route.query
        };
        delete query.filters;
        this.$router.replace({
          ...this.$route,
          query
        });
      } catch (e) {
        ////console.log("Incorrect filters");
      }
    }
    return {
      show_table_settings_columns: false,
      show_table_settings_filters: false,
      columnSettings: null,
      filters,
      ranges: {},
      sort: null,
      pagination: {
        pageSize: this.pageSize,
        page: 1
      },
      loadedItems: null,
      rawResponse: null,
      total: 0,
      count: 0,
      selectedItems: [],
      loading: false,
      error: false,
      refreshKey: 0,
      trackId: null,
      exporting: false,
      freshing: false
    };
  },
  computed: {
    config() {
      return {
        loader: this.loader,
        loaderConfig: this.loaderConfig,
        query: this.queryParams,
        refreshKey: this.refreshKey
      };
    },
    loaderConfigHash() {
      return hash(JSON.stringify(this.loaderConfig));
    },
    configHash() {
      return hash(JSON.stringify(this.config));
    },
    columnsExtended() {
      if (this.columnSettings) {
        return this.columns.map(column => {
          return {
            type: "text",
            sort: true,
            ...column,
            ...this.columnSettings.find(i => i.name === column.name && !column.noSettings)
          };
        }).sort((a, b) => a.position - b.position).filter(column => !!column.show);
      }
      return this.columns;
    },
    filtersQueryParams() {
      const query = {};
      // filters
      const values = this.filters || {};
      Object.keys(values).forEach(field => {
        const filter = this.filtersAvailable.find(i => i.name === field);
        if (values[field] === undefined) {
          return;
        }
        switch (filter.type) {
          case "text":
          case "tags":
            query[`f.${field}`] = values[field];
            break;
          case "numberRange":
          case "dateRange":
            if (values[field][0]) {
              query[`f.${field}.min`] = values[field][0];
            }
            if (values[field][1]) {
              query[`f.${field}.max`] = values[field][1];
            }
            break;
          case "select":
            query[`f.${field}`] = values[field];
            break;
        }
      });
      return query;
    },
    sortQueryParams() {
      const query = {};
      // sorting
      if (this.sort) {
        query.sort = this.sort.column;
        query.sort_dir = this.sort.direction;
      }
      return query;
    },
    paginationQueryParams() {
      const query = {};
      // pagination
      if (this.pagination) {
        query.page_size = this.pagination.pageSize;
        query.page = this.pagination.page;
      }
      return query;
    },
    queryParams() {
      const query = {
        select: ["items", ...(this.configurable ? ["ranges"] : [])],
        ...(this.configurable ? this.filtersQueryParams : {}),
        ...this.sortQueryParams,
        ...this.paginationQueryParams
      };
      return query;
    },
    filtersAvailable() {
      return this.columns.map(column => {
        if (!column.filter) {
          return undefined;
        }
        let filter = null;
        if (typeof column.filter === "string") {
          filter = {
            name: column.name,
            type: column.filter,
            title: column.title
          };
        } else {
          filter = {
            name: column.name,
            title: column.title,
            ...column.filter
          };
        }
        if (filter.type === "select" && !filter.loader) {
          if (column.values) {
            filter.loader = ({
              ids /*, query */
            }) => {
              if (ids) {
                return ids.map(id => {
                  return {
                    title: column.values[id],
                    id: id
                  };
                });
              }
              return Object.keys(column.values).map(id => {
                return {
                  title: column.values[id],
                  id: id
                };
              });
            };
            filter.idProp = "id";
          } else {
            // default loader
            filter.loader = async ({
              ids,
              query
            }) => {
              if (ids) {
                return ids.map(id => {
                  return {
                    title: id
                  };
                });
              }
              const {
                values
              } = await this.loader({
                config: this.loaderConfig,
                query: {
                  //...this.filtersQueryParams,
                  // ...this.sortQueryParams,
                  select: ["values"],
                  field: column.name,
                  field_values_query: query
                }
              });
              return values[column.name] ? values[column.name].map(value => {
                return {
                  title: value
                };
              }) : [];
            };
          }
        }
        return filter;
      }).filter(i => i !== undefined);
    },
    settingsChanged() {
      return this.columnSettings && this.columnSettings.length;
    },
    filtersChanged() {
      return this.filters && Object.keys(this.filters).length;
    }
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    /**
     * Public method
     */
    refresh() {
      this.refreshKey++;
    },
    /**
     * Public method, same as refresh but without loading indicator
     */
    async refreshSilent() {
      await this.fetch({
        silent: true
      });
    },
    clean() {
      this.selectedItems = [];
    },
    async loadSettings() {
      //console.log('558: this.$store.state.user.user=', this.$store.state.user.user)

      if (!this.$store.state.user.user) {
        return;
      }

      //console.log('564: this.persistSettings=', this.persistSettings)

      if (!this.persistSettings) {
        return;
      }
      let remoteSettings = await this.$store.dispatch("table/getSettings", {
        table_id: this.tableId
      });

      //console.log('576: remoteSettings=', remoteSettings)

      //&:2024-09-02
      if (!remoteSettings || !Array.isArray(remoteSettings)) {
        remoteSettings = [];
      }

      //console.log('583: remoteSettings=', remoteSettings)

      if (remoteSettings && Array.isArray(remoteSettings)) {
        const settings = remoteSettings.map(remoteColumn => {
          if (!(remoteColumn !== null && remoteColumn !== void 0 && remoteColumn.name)) {
            return undefined;
          }
          const column = {
            name: remoteColumn.name
          };
          if (typeof remoteColumn.show === "boolean") {
            column.show = remoteColumn.show;
          }
          if (typeof remoteColumn.position === "number") {
            column.position = remoteColumn.position;
          }
          return column;
        }).filter(item => !!item);
        this.$set(this, "columnSettings", settings);

        //console.log('598: settings=', settings)
      }
    },
    onSettingsChange(v) {
      this.columnSettings = v;
      if (!this.$store.state.user.user) {
        return;
      }
      if (!this.persistSettings) {
        return;
      }
      this.$store.dispatch("table/putSettings", {
        table_id: this.tableId,
        data: this.columnSettings
      });
    },
    onSelectAction_RrcPriceFresh() {
      //all = false
      //this.$access.hasOrThrow("lists");

      this.$emit("selectAction_RrcPriceFresh", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        }
      });
    },
    onSelectAction(all = false) {
      this.$access.hasOrThrow("lists");
      this.$emit("selectAction", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        },
        addToList: ({
          entity,
          mp
        }) => {
          if (all) {
            this.$refs.modalListAddItems.open({
              saveFunction: async ({
                listId
              }) => {
                const {
                  action_result
                } = await this.loader({
                  config: this.loaderConfig,
                  query: {
                    select: [],
                    ...(this.configurable ? this.filtersQueryParams : {}),
                    ...this.sortQueryParams,
                    action: "add_to_list",
                    action_param: listId
                  }
                });
                return action_result;
              },
              entity,
              mp
            });
          } else {
            this.$refs.modalListAddItems.open({
              items: this.selectedItems,
              entity,
              mp,
              onComplete: () => {
                this.clean();
              }
            });
          }
        },
        update: () => {
          return all;
        }
      });
    },
    onSelectAction1(all = false) {
      this.$emit("selectAction", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        },
        update: () => {
          return all;
        }
      });
    },
    onSelectAction2(all = false) {
      this.$emit("selectAction2", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        },
        update: () => {
          return all;
        }
      });
    },
    onSelectAction3(all = false) {
      this.$emit("selectAction3", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        },
        update: () => {
          return all;
        }
      });
    },
    onSelectAction4(all = false) {
      this.$emit("selectAction4", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        },
        update: () => {
          return all;
        }
      });
    },
    onSelectActionM(all = false) {
      this.$access.hasOrThrow("lists");
      this.$emit("selectActionM", {
        items: [...this.selectedItems],
        clean: () => {
          this.clean();
        },
        refresh: () => {
          this.refresh();
        },
        addToListM: ({
          entity,
          mp
        }) => {
          // alert("addToListM(): 1");

          if (all) {
            // alert("addToListM(): all=" + JSON.stringify(all));

            this.$refs.modalListMAddItems.open({
              saveFunction: async ({
                listId
              }) => {
                const {
                  action_result
                } = await this.loader({
                  config: this.loaderConfig,
                  query: {
                    select: [],
                    ...(this.configurable ? this.filtersQueryParams : {}),
                    ...this.sortQueryParams,
                    action: "add_to_list",
                    action_param: listId
                  }
                });
                return action_result;
              },
              entity,
              mp
            });
          } else {
            // alert("addToListM(): 3");

            this.$refs.modalListMAddItems.open({
              items: this.selectedItems,
              entity,
              mp,
              onComplete: () => {
                this.clean();
              }
            });
          }
        },
        update: () => {
          return all;
        }
      });
    },
    async onExport() {
      //console.log('onExport().707')
      this.$access.hasOrThrow("export");
      this.exporting = true;

      //console.log('onExport().712')

      try {
        // see plugins/api interceptors
        const responseData = await this.loader({
          config: this.loaderConfig,
          query: {
            select: [],
            //! как такое мб ???
            ...(this.configurable ? this.filtersQueryParams : {}),
            ...this.sortQueryParams,
            action: "export",
            action_param: this.columnsExtended.map(column => [column.name, column.title])
          }
        });
        //console.log('onExport(): responseData=', responseData)
        const fileName = this.fileName || `Экспорт ${today("DD.MM.YYYY HH:mm")}`;
        if (responseData.type === "excel") {
          responseData.fileName = fileName;

          ////console.log('ExportJsonExcel()');

          let toExcel = new ExportJsonExcel(responseData); //new
          toExcel.saveExcel(); //保存
        } else {
          const url = window.URL.createObjectURL(new Blob([responseData]));
          const link = document.createElement("a");
          link.href = url;

          ////console.log('&: Export');

          ////@V:
          ////link.setAttribute('download', `${fileName}.csv`); //or any other extension
          link.setAttribute("download", `${fileName}.xlsx`); //!&:

          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        this.exporting = false; // мб сбои - для следующей попытки
      }
      this.exporting = false;
    },
    async fetch({
      silent = false
    } = {}) {
      var _e$response3;
      if (!silent) {
        this.loading = true;
      }
      this.error = false;
      const getTrackParams = () => {
        if (!this.track) {
          return {};
        }
        if (this.trackId) {
          return {
            trackId: this.trackId
          };
        }
        return {
          track: this.track
        };
      };
      let data;
      try {
        data = await this.loader({
          config: this.loaderConfig,
          query: {
            ...this.queryParams,
            ...getTrackParams()
          }
        });
      } catch (e) {
        var _e$response;
        if (((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 402) {
          var _e$response2;
          switch ((_e$response2 = e.response) === null || _e$response2 === void 0 ? void 0 : _e$response2.data.key) {
            case "keywordOverviewAll_PerDayLimit":
            case "reportTableRequestsPerDay":
              if (this.$store.state.user.user) {
                this.$modal.open(() => import("@/components/modals/access/ModalLimitReached"), {
                  ...(e.response.data || {})
                });
              } else {
                this.$modal.open(() => import("@/components/modals/access/ModalRegister"), {
                  message:
                  //@V: "Исчерпан лимит запросов для анонимных пользователей",
                  "Данный функционал доступен только для зарегистрированных пользователей"
                });
              }
              break;

            /*
            case "keywordOverviewAll_PerDay_quota":
                if (this.$store.state.user.user) {
                    this.$modal.open(
                        () =>
                            import(
                                "@/components/modals/access/Modal_PerDayLimit_Reached"
                            ),
                        { ...(e.response.data || {}) }
                    );
                } else {
                    this.$modal.open(
                        () =>
                            import(
                                "@/components/modals/access/Modal_PerDayLimit_Register"
                            ),
                        {
                            message:
                                "Данный функционал доступен только для зарегистрированных пользователей",
                        }
                    );
                }
                break;
            */

            case "reportTableLimit":
              if (this.$store.state.user.user) {
                this.$modal.open(() => import("@/components/modals/access/ModalDataLimit"), {
                  ...(e.response.data || {})
                });
              } else {
                this.$modal.open(() => import("@/components/modals/access/ModalRegister"), {
                  message: "Полные данные доступны для пользователей с платной подпиской"
                });
              }
              this.pagination.pageSize = ((_e$response3 = e.response) === null || _e$response3 === void 0 ? void 0 : _e$response3.data.limit) || 20;
              this.pagination.page = 1;
              return;
          }
          data = {
            items: [],
            total: 0
          };
        } else {
          if (!silent) {
            this.loading = false;
          }
          this.error = true;
          throw e;
        }
      }
      if (!silent) {
        this.loading = false;
      }
      this.loadedItems = data.items;
      this.count = data.items.length;
      this.total = data.total;
      if (data.page_size) {
        this.pagination.pageSize = Number(data.page_size);
      }
      if (data.ranges) {
        this.ranges = data.ranges;
      }
      this.rawResponse = data;
      if (this.track && data.trackId) {
        this.trackId = data.trackId;
      }
    }
  },
  watch: {
    filters(val, oldVal) {
      if (hash(JSON.stringify(val)) !== hash(JSON.stringify(oldVal))) {
        this.pagination.page = 1;
      }
    },
    loaderConfigHash() {
      this.trackId = null;
      this.pagination.page = 1;
    },
    async forceLoading() {
      this.refresh();
    },
    configHash: {
      immediate: true,
      async handler() {
        await this.fetch();
      }
    }
  }
};
export const ColWidth = colName => {
  const aCOL_WIDTH = {
    'seller': 240
  };
  let k = Object.keys(aCOL_WIDTH).find(k => k == colName);
  if (k) return aCOL_WIDTH[k];
  return -1;
};
export default DataTable;
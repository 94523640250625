var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.model,
      expression: "model"
    }],
    staticClass: "table-settings__filters-item-input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.model = $event.target.value;
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
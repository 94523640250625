import { FadeTransition } from "vue2-transitions";
import { plural } from "@/utils/numbers";
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      default: "add",
      validate: v => ["add", "remove", "update", "multiple-approve", "repricer-products-edit", "repricer-products-edit-compensation"].includes(v)
    }
  },
  data() {
    return {
      input: "",
      newItems: this.items
    };
  },
  computed: {
    compText() {
      const len = this.items.length;
      return plural(len, ["Выбрана {v} позиция", "Выбрано {v} позиции", "Выбрано {v} позиций"]);
    }
  },
  methods: {
    deselectAll() {
      this.$emit("update:items", []);
    },
    selectAction1() {
      this.$emit("action1");
    },
    selectAction2() {
      this.$emit("action2");
    },
    selectAction3() {
      this.$emit("action3");
    },
    selectAction4() {
      this.$emit("action4");
    }
  },
  components: {
    FadeTransition
  }
};
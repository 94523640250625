var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Создание списка мониторинга",
      "width": 420,
      "actions": _vm.actions
    }
  }, [_c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Название")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.title,
      expression: "new_list.title"
    }],
    staticClass: "bold",
    attrs: {
      "type": "text",
      "placeholder": "Название списка мониторинга",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.new_list.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v(" Тип списка и параметр для мониторинга ")]), _c('div', {
    staticStyle: {
      "display": "grid",
      "grid-template-columns": "1fr 1fr",
      "column-gap": "10px"
    }
  }, [_c('app-select', {
    attrs: {
      "items": _vm.select_entity_items,
      "fullwidth": "",
      "disabled": !!_vm.entity
    },
    model: {
      value: _vm.new_list.entity,
      callback: function ($$v) {
        _vm.$set(_vm.new_list, "entity", $$v);
      },
      expression: "new_list.entity"
    }
  }), _c('app-select', {
    attrs: {
      "items": _vm.current_select_monitor_items,
      "fullwidth": ""
    },
    model: {
      value: _vm.new_list.monitor,
      callback: function ($$v) {
        _vm.$set(_vm.new_list, "monitor", $$v);
      },
      expression: "new_list.monitor"
    }
  })], 1)]), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Маркетплейс")]), _c('app-select', {
    attrs: {
      "items": _vm.select_marketplace_items,
      "fullwidth": "",
      "disabled": !!_vm.mp
    },
    model: {
      value: _vm.new_list.mp,
      callback: function ($$v) {
        _vm.$set(_vm.new_list, "mp", $$v);
      },
      expression: "new_list.mp"
    }
  })], 1), _c('div', {
    staticClass: "modal-item"
  }, [_c('div', {
    staticClass: "modal-item__label"
  }, [_vm._v("Описание")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.new_list.description,
      expression: "new_list.description"
    }],
    attrs: {
      "placeholder": "Введите описание (опционально)"
    },
    domProps: {
      "value": _vm.new_list.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.new_list, "description", $event.target.value);
      }
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
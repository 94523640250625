var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "filters-select"
  }, [_c('div', {
    staticClass: "filters-select__main",
    class: {
      'filters-select-open': _vm.isOpen
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.toggleSelect.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('svg', {
    staticClass: "filters-select-arrow",
    attrs: {
      "width": "12",
      "height": "8",
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.293 0.439453L5.99997 4.73245L1.70697 0.439453L0.292969 1.85345L5.99997 7.56045L11.707 1.85345L10.293 0.439453Z",
      "fill": "black"
    }
  })])]), _c('slide-y-up-transition', [_vm.isOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeSelect,
      expression: "closeSelect"
    }],
    staticClass: "filters-select__popup"
  }, [_c('div', {
    staticClass: "filters-select__search"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }), _c('svg', {
    attrs: {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12.25 12.25L9.625 9.625M10.5 6.125C10.5 8.54125 8.54125 10.5 6.125 10.5C3.70875 10.5 1.75 8.54125 1.75 6.125C1.75 3.70875 3.70875 1.75 6.125 1.75C8.54125 1.75 10.5 3.70875 10.5 6.125Z",
      "stroke": "#333333",
      "stroke-opacity": "0.6",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]), _vm._l(_vm.visibleItems, function (i) {
    return _c('div', {
      key: i[_vm.idProp],
      staticClass: "filters-select__item"
    }, [_c('checkbox', {
      attrs: {
        "model-value": _vm.getCheckboxState(i),
        "title": i.title,
        "centered": false
      },
      on: {
        "change": function ($event) {
          return _vm.onCheckboxChange(i, $event);
        }
      }
    })], 1);
  }), _c('div', {
    staticClass: "filters-select__bottom"
  }, [_c('button', {
    staticClass: "btn btn-blue",
    on: {
      "click": _vm.apply
    }
  }, [_vm._v(" Применить ")])])], 2) : _vm._e()])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
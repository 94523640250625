var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('fade-transition', [_c('div', {
    staticClass: "items-popup"
  }, [_c('div', {
    staticClass: "items-popup__text"
  }, [_vm._v(" " + _vm._s(_vm.compText) + " ")]), _vm.action === 'add' ? [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('action');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/save.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Добавить в список ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('actionM');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/save.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Добавить в мониторинг ")])] : _vm.action === 'rrcPriceFresh' ? [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('action_rrcPriceFresh');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/update.svg`),
      "width": "18",
      "height": "18"
    }
  }), _vm._v(" Обновить цены ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('action');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/trash.svg`)
    }
  }), _vm._v(" Убрать из списка ")])] : _vm.action === 'remove' ? [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('action');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/trash.svg`)
    }
  }), _vm._v(" Убрать из списка ")])] : _vm.action === 'update' ? [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.input,
      expression: "input"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Новый Коэффициент"
    },
    domProps: {
      "value": _vm.input
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.input = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction1
    }
  }, [_c('img', {
    staticClass: "update-icon",
    attrs: {
      "src": require(`@/assets/images/icons/update.svg`)
    }
  }), _vm._v(" Обновить ")])] : _vm.action === 'multiple-approve' ? [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction1
    }
  }, [_vm._v(" Одобрить и отправить все ")])] : _vm.action === 'repricer-products-edit' ? [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction1
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/trash.svg`)
    }
  }), _vm._v(" Удалить ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction2
    }
  }, [_vm._v(" Указать минимальную цену ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction3
    }
  }, [_vm._v(" Указать максимальную цену ")])] : _vm.action === 'repricer-products-edit-compensation' ? [_c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction1
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/trash.svg`)
    }
  }), _vm._v(" Удалить ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction2
    }
  }, [_vm._v(" Указать минимальную цену ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction3
    }
  }, [_vm._v(" Указать максимальную цену ")]), _c('button', {
    staticClass: "btn btn-outline",
    on: {
      "click": _vm.selectAction4
    }
  }, [_vm._v(" Указать ожидаемую цену ")])] : _vm._e(), _c('button', {
    staticClass: "btn btn-table-popup",
    on: {
      "click": _vm.deselectAll
    }
  }, [_vm._v(" Снять выбор ")])], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('modal', {
    ref: "modal",
    attrs: {
      "title": "Добавить в список",
      "actions": _vm.actions
    }
  }, [_vm.lists !== null ? [_vm.lists.length == 0 ? _c('div', {
    staticClass: "modal-list-text"
  }, [_vm._v(" " + _vm._s(_vm.emptyText) + " ")]) : _vm._e(), _vm._l(_vm.lists, function (list) {
    return _c('div', {
      key: list.id,
      staticClass: "modal-list-item",
      class: {
        'modal-list-item-selected': list.id === _vm.selectedListId
      },
      on: {
        "click": function ($event) {
          return _vm.selectList(list.id);
        }
      }
    }, [_vm._v(" " + _vm._s(list.title) + " (" + _vm._s(list.count) + " шт.) "), list.id === _vm.selectedListId ? _c('svg', {
      attrs: {
        "width": "20",
        "height": "16",
        "viewBox": "0 0 20 16",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M6.33264 11.781L1.94197 7.3903L0.0566406 9.27563L6.33264 15.5516L19.2753 2.60897L17.39 0.723633L6.33264 11.781Z",
        "fill": "#316D92"
      }
    })]) : _vm._e()]);
  }), _c('div', {
    staticClass: "modal-list-item modal-list-item-btn-new",
    on: {
      "click": _vm.openNewListModal
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/icons/plus.svg`)
    }
  }), _vm._v(" Новый список ")])] : _vm._e()], 2), _c('modal-add-list', {
    ref: "modalAddList",
    on: {
      "complete": _vm.onListAdded
    }
  }), _c('modal-list-import-result', {
    ref: "modalListImportResult"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('TagsInput', {
    attrs: {
      "tags": _vm.tags,
      "placeholder": _vm.placeholder
    },
    on: {
      "tags-changed": newTags => _vm.tags = newTags
    },
    model: {
      value: _vm.tag,
      callback: function ($$v) {
        _vm.tag = $$v;
      },
      expression: "tag"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
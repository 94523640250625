var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "ui-input-group",
    class: {
      fillHeight: _vm.fillHeight
    }
  }, [_vm.label ? _c('label', {
    staticClass: "label",
    attrs: {
      "for": `ui-${_vm.uid}`
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _c('VueTagsInput', _vm._g(_vm._b({
    staticClass: "input",
    class: ['ui-tags-input'],
    attrs: {
      "id": `ui-${_vm.uid}`
    }
  }, 'VueTagsInput', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
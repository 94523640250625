var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('range-calendar', {
    attrs: {
      "value": _vm.value,
      "allow-empty": "",
      "full-width-button": "",
      "button-align": "left"
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event);
      }
    }
  });
};
var staticRenderFns = [];
export { render, staticRenderFns };
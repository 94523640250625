import VueTagsInput from "@johmun/vue-tags-input";
export default {
  components: {
    VueTagsInput
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    fillHeight: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uid() {
      return this._uid;
    }
  }
};
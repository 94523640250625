import "core-js/modules/esnext.iterator.map.js";
import TagsInput from "@/components/common/ui/Form/TagsInput.vue";
import { createTags } from "@johmun/vue-tags-input";
export default {
  components: {
    TagsInput
  },
  props: {
    value: {
      type: Array,
      default: undefined
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    var _this$value;
    return {
      tag: "",
      tags: (_this$value = this.value) !== null && _this$value !== void 0 && _this$value.length ? createTags(this.value) : []
    };
  },
  watch: {
    value(newVal, OldVal) {
      if (!newVal) {
        this.tags = [];
        this.tag = "";
        return;
      }
      if ((newVal === null || newVal === void 0 ? void 0 : newVal.length) === (OldVal === null || OldVal === void 0 ? void 0 : OldVal.length)) return;
      this.tags = createTags(newVal);
    },
    tags(newVal) {
      this.$emit("input", !newVal.length ? undefined : newVal.map(tag => tag.text));
    }
  }
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "scrollable"
  }, [_c('vuescroll', {
    attrs: {
      "ops": _vm.ops
    }
  }, [_vm._t("default")], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pagination"
  }, [_c('div', {
    staticClass: "info"
  }, [_c('span', [_vm._v(" Показано "), _vm.range ? [_vm._v(" " + _vm._s(_vm.range[0]) + " - " + _vm._s(_vm.range[1]) + " ")] : [_vm._v(" 0 ")], _vm._v(" (всего " + _vm._s(_vm.total) + " позиций) ")], 2), _c('app-select', {
    attrs: {
      "items": _vm.selectOptions,
      "mini": "",
      "openUp": "",
      "width": "54"
    },
    model: {
      value: _vm.selectModel,
      callback: function ($$v) {
        _vm.selectModel = $$v;
      },
      expression: "selectModel"
    }
  })], 1), _vm.links.length > 0 ? _c('div', {
    staticClass: "pages"
  }, [_c('span', [_vm._v("Страница")]), _vm.page > 1 ? _c('img', {
    staticClass: "pages__link pag-prev",
    attrs: {
      "src": require(`@/assets/images/icons/pagination-arrow.svg`)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.page -= 1;
      }
    }
  }) : _vm._e(), _vm._l(_vm.links, function (p, i) {
    return _c('div', {
      key: i,
      staticClass: "pages__link",
      class: {
        'pages__link-active': p == _vm.page
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          p !== '...' ? _vm.page = p : '';
        }
      }
    }, [_vm._v(" " + _vm._s(p) + " ")]);
  }), _vm.page < _vm.pagesCount ? _c('img', {
    staticClass: "pages__link",
    attrs: {
      "src": require(`@/assets/images/icons/pagination-arrow.svg`)
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.page += 1;
      }
    }
  }) : _vm._e()], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "table-settings__filters-item-range-block"
  }, [_c('div', {
    staticClass: "table-settings__filters-item-range-block-top"
  }, [_c('div', {
    staticClass: "table-settings__filters-item-range-block-top-item"
  }, [_c('span', [_vm._v("От")]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.range[0]
    },
    domProps: {
      "value": _vm.model[0]
    },
    on: {
      "input": function ($event) {
        _vm.model = [$event.target.value, _vm.model[1]];
      }
    }
  })]), _c('div', {
    staticClass: "table-settings__filters-item-range-block-top-item"
  }, [_c('span', [_vm._v("До")]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.range[1]
    },
    domProps: {
      "value": _vm.model[1]
    },
    on: {
      "input": function ($event) {
        _vm.model = [_vm.model[0], $event.target.value];
      }
    }
  })])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };